import React from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Survey from "./screens/Survey";
import Aspire from "./screens/Aspire";

const App = () => {
  return (
    <div>
      <Survey />
    </div>
    // <Router>
    //   <Routes>
    //     <Route path="/survey/:id" element={<Survey />} />
    //     {/* <Route path="/customer" element={<Customer />} />
    //       <Route path="/customer/:id" element={<LeverDetail />} /> */}

    //     {/* <Route path="detail" element={<LeverDetail />} /> */}
    //     <Route path="/aspire" element={<Aspire />} />
    //   </Routes>
    // </Router>
  );
};

export default App;
