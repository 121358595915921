import React, { useState, useEffect } from "react";
import axios from "axios";
// import { useLocation } from "react-router-dom";

const Survey = () => {
  const [surveyQns, setSurveyQns] = useState([]);
  const [surveyid, setSurveyId] = useState(null);
  const [expandedUrl, setExpandedUrl] = useState("");

  const expandUrl = async (shortUrl) => {
    try {
      const response = await axios.head(shortUrl);
      const expanded = response.request.responseURL;
      setExpandedUrl(expanded);
    } catch (error) {
      console.error("Error expanding URL:", error);
    }
  };
  // const location = useLocation();

  // const queryParams = new URLSearchParams(location.search);

  const decryptText = (text) => {
    const substitutionMap = {
      ers: "a",
      bnx: "b",
      iuo: "c",
      dyp: "d",
      knd: "e",
      che: "f",
      sow: "g",
      fwk: "h",
      ija: "i",
      kml: "j",
      gvq: "k",
      sed: "l",
      jmn: "n",
      hjs: "o",
      wsl: "p",
      kja: "q",
      lad: "r",
      wlb: "s",
      fvh: "t",
      few: "u",
      vkh: "v",
      yel: "w",
      yus: "x",
      lkn: "y",
      sdi: "z",
    };

    let decryptedText = "";
    let index = 0;
    while (index < text.length) {
      const triplet = text.substr(index, 3);
      if (substitutionMap.hasOwnProperty(triplet)) {
        decryptedText += substitutionMap[triplet];
        index += 3;
      } else {
        const character = text[index];
        if (substitutionMap.hasOwnProperty(character)) {
          decryptedText += substitutionMap[character];
          index++;
        } else if (!isNaN(parseInt(character))) {
          // If the character is numeric, skip it
          decryptedText += character;
          index++;
        } else {
          decryptedText += character;
          index++;
        }
      }
    }
    return decryptedText;
  };

  useEffect(() => {
    const surveyid = window.location.pathname.split("/")[1];

    //   const surveyid = queryParams.get("id");

    const decryptedParameter = surveyid;
    if (decryptedParameter) {
      setSurveyId("https://zohsy.in/" + decryptedParameter);
    }

    expandUrl("https://zohsy.in/13s");
    setSurveyQns([
      {
        question:
          "Do you feel satisfied with your current role in the company?",
        questionType: "range",
      },
      {
        question:
          "Do you believe there is a clear path for career advancement in our company?",
        questionType: "onemultiplechoice",
      },
      {
        question: "Are you proud to be an employee of this company?",
        questionType: "manymultiplechoice",
      },
      {
        question: "Do you find your workload to be manageable?",
        questionType: "star",
      },
      {
        question: "Do you find your job role challenging and engaging?",
        questionType: "textbox",
      },
      {
        question:
          "Is the feedback you receive from your manager constructive and useful?",
        questionType: "range",
      },
      {
        question:
          "Are you satisfied with the opportunities to contribute ideas and feedback?",
        questionType: "range",
      },
      {
        question:
          "Do you think the company has a strong competitive position in the industry?",
        questionType: "range",
      },
    ]);
  }, []);

  const Range = ({ ranges }) => {
    const [value, setValue] = useState(0);

    const handleChange = (event) => {
      const newValue = parseInt(event.target.value);
      if (ranges.includes(newValue)) {
        setValue(newValue);
      }
    };

    return (
      <div>
        <input
          type="range"
          min={Math.min(...ranges)}
          max={Math.max(...ranges)}
          step={10}
          value={value}
          onChange={handleChange}
          style={{
            backgroundColor: "#000",
            margin: "20px auto",
            color: "#000",
          }}
        />
        <span>{value}</span>
      </div>
    );
  };

  const ranges = [0, 10, 20, 30];

  return (
    <div style={{ display: "flex", flex: 1 }}>
      {/* {surveyid === null && (
        <div>
          <div
            style={{
              padding: "1px",
              borderRadius: "10px",
              backgroundColor: "#2ca7e0",
              cursor: "pointer",
            }}
          >
            <p>Upload Sheet</p>
          </div>
        </div>
      )} */}

      {surveyid !== null && (
        <iframe
          title="Embedded Content"
          width="100%"
          height={window.innerHeight}
          src={surveyid}
          frameBorder="0"
          allowFullScreen
        ></iframe>
      )}
    </div>
  );
};

export default Survey;
